<template>
    <div class="goods-detail">
        <div class="preview-wrap">
            <div class="video-player-wrap" :class="{ show: switchMedia == 'video' }"
                v-if="goodsSkuDetail.video_url != ''">
                <video-player v-if="goodsSkuDetail.video_url != ''" ref="videoPlayer" :playsinline="true"
                    :options="playerOptions" @play="onPlayerPlay($event)" @pause="onPlayerPause($event)"
                    @ended="onPlayerEnded($event)" @waiting="onPlayerWaiting($event)" @playing="onPlayerPlaying($event)"
                    @loadeddata="onPlayerLoadeddata($event)" @timeupdate="onPlayerTimeupdate($event)"
                    @canplay="onPlayerCanplay($event)" @canplaythrough="onPlayerCanplaythrough($event)"
                    @statechanged="playerStateChanged($event)" @ready="playerReadied"></video-player>

                <div class="media-mode" v-if="goodsSkuDetail.video_url != ''">
                    <span :class="{ 'ns-bg-color': switchMedia == 'video' }" @click="switchMedia = 'video'">视频</span>
                    <span :class="{ 'ns-bg-color': switchMedia == 'img' }" @click="switchMedia = 'img'">图片</span>
                </div>
            </div>
            <!-- , { size: 'big' } -->
            <div class="magnifier-wrap">
                <pic-zoom ref="PicZoom" :url="$img(picZoomUrl)" :scale="2"></pic-zoom>
            </div>

            <div class="spec-items">
                <span class="left-btn iconfont icon-arrow-left-copy" :class="{ move: moveThumbLeft }"
                    @click="changeThumbImg('prev')"></span>
                <span class="right-btn iconfont icon-arrow-right" :class="{ move: moveThumbRight }"
                    @click="changeThumbImg('next')"></span>
                <ul :style="{ left: 16 + thumbPosition + 'px' }">
                    <!-- 商品缩率图 -->
                    <li v-for="(item, index) in goodsSkuDetail.sku_images" :key="index" @mousemove="picZoomUrl = item"
                        :class="{ selected: picZoomUrl == item }">
                        <img :src="$img(item)" @error="imageErrorSpec(index)" />
                    </li>
                </ul>
            </div>

            <div class="share-collect">
                <div @click="editCollection">
                    <!-- <i class="iconfont" :class="whetherCollection == 1 ? 'iconlikefill ns-text-color' : 'iconlike'"></i>
                    <span data-collects="0">关注商品（{{ goodsSkuDetail.collect_num }}）</span> -->
                    <i class="iconfont"
                        :class="whetherCollection == 1 ? 'iconlikefill ns-text-color' : 'iconlikefill'"></i>
                    <span data-collects="0" class="focus-text" v-if="goodsSkuDetail.collect_num > 0">关注商品（{{
                        goodsSkuDetail.collect_num }}）</span>
                    <span data-collects="0" class="focus-text" v-else>关注商品</span>
                </div>
                <div v-if="kefuConfig.system == 0 && kefuConfig.open_pc == 1">
                    <i class="iconfont iconzhanghao"></i>
                    <span data-collects="0"><a :href="kefuConfig.open_url" target="_blank">联系客服</a></span>
                </div>
                <div @click="service_link" v-else-if="kefuConfig.system == 1">
                    <i class="iconfont iconzhanghao"></i>
                    <span data-collects="0">联系客服</span>
                </div>
            </div>
        </div>

        <!-- 特价信息 -->
        <div class="basic-info-wrap" v-loading="loading">
            <!-- 价格类型展示 -->
            <vshowPirceType :goodsSkuDetail="goodsSkuDetail" :token="token" :goods_member_price="goods_member_price"
                :activeinfo="activeinfo" :discountTimeMachine="discountTimeMachine" :addonIsExit="addonIsExit"
                :discountText="discountText" @countDownS_cb="countDownS_cb" @countDownE_cb="countDownE_cb">
            </vshowPirceType>
            <!-- 商品属性展示 -->
            <vModelDeatail :manjian="manjian" :manjians="manjians" :couponList="couponList" :activeinfo="activeinfo"
                :goodsSkuDetail="goodsSkuDetail" @receiveCoupon="receiveCoupon"> </vModelDeatail>

            <dl class="item-line service">
                <dt>服务</dt>
                <dd>
                    <span>
                        由
                        <span class="ns-text-color">{{ siteInfo.site_name }}</span>
                        发货并提供售后服务
                    </span>
                </dd>
            </dl>

            <hr class="divider" />
            <!-- 输入数量 -->
            <vInputNum v-model="number" :goodsSkuDetail="goodsSkuDetail"></vInputNum>
            <!-- 加入购物车提交 -->
            <vgoodsSubmit :goodsSkuDetail="goodsSkuDetail" :token="token" :buynowdisabled="buynowdisabled"
                :number="number" :btnSwitch="btnSwitch" @joinCart="joinCart" :service_list="service_list">
            </vgoodsSubmit>

            <!-- shopInfo.shop_baozh == 1 || shopInfo.shop_qtian == 1 || shopInfo.shop_zhping == 1 || shopInfo.shop_erxiaoshi == 1 || shopInfo.shop_tuihuo == 1 || shopInfo.shop_shiyong == 1 || shopInfo.shop_shiti == 1 || shopInfo.shop_xiaoxie == 1 -->

        </div>
        <!-- 组合套餐 -->
        <template v-if="addonIsExit.bundling && bundling.length && bundling[0].bl_name && token && goodsSkuDetail.goods_state == 1">
            <vShowbundling :tabBundling="tabBundling" :addonIsExit="addonIsExit" :bundling="bundling"
                :minGoodsBundling="minGoodsBundling" :goodsSkuDetail="goodsSkuDetail" :token="token"></vShowbundling>
        </template>

        <!-- 换购 -->
        <template v-if="goodsSkuDetail.get_free_goods !== null && goodsSkuDetail.goods_state == 1">
            <vShowGetfree :goodsSkuDetail="goodsSkuDetail">
            </vShowGetfree>
        </template>
        <!-- 商品信息 -->
        <div class="detail-wrap">
            <div class="goods-recommended">
                <goods-recommend />
            </div>

            <!-- 商品详情 -->
            <vGoodsdetails v-model="tabName" :goodsSkuDetail="goodsSkuDetail" :evaluate_show="evaluate_show"
                :evaluteCount="evaluteCount" :goodsEvaluateList="goodsEvaluateList" :service="service"
                :service_is_display="service_is_display" :evaluaType="evaluaType" :currentPage="currentPage" :pageSize="pageSize" :total="total"></vGoodsdetails>
        </div>

        <!--联系客服弹窗-->
        <servicerMessage ref="servicerMessage" class="kefu"
            :shop="{ shop_id: shopInfo.site_id, logo: shopInfo.logo, shop_name: shopInfo.site_name }"></servicerMessage>
    </div>
</template>

<script>
import PicZoom from "vue-piczoom"
import detail from "./detail"
import GoodsRecommend from "@/components/GoodsRecommend"
import servicerMessage from "@/components/message/servicerMessage"
import vModelDeatail from "./modelDeatail"
import vshowPirceType from "./showPirceType"
import vShowbundling from "./showbundling"
import vGoodsdetails from "./goodsdetails"
import vShowGetfree from "./showGetfree"
import vInputNum from "./inputNum.vue"
import vgoodsSubmit from "./goodsSubmit"
export default {
    name: "detail",
    components: {
        PicZoom,
        GoodsRecommend,
        servicerMessage,
        vModelDeatail,
        vshowPirceType,
        vShowbundling,
        vGoodsdetails,
        vShowGetfree,
        vInputNum,
        vgoodsSubmit,
    },
    mixins: [detail]
}
</script>
<style lang="scss">
.trade-in-content {
    // width: 78%;
    //border: 1px solid red;
    // margin-left: 264px;
    margin-bottom: 20px;

    .trade-in-title {
        padding: 0 20px;
        margin-bottom: 20px;
        width: 100%;
        height: 38px;
        background-color: #fc1f4f;
        display: flex;
        align-items: center;

        >p {
            font-size: 16px;
            color: #ffffff;
        }

    }

    .trade-in-goods {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .goods-item {
            width: 120px;
            display: flex;
            align-items: center;
            flex-direction: column;

            img {
                width: 100%;
            }
        }

        .name_count {
            line-height: normal;
            width: 100%;

            // display: flex;
            // align-items: center;
            .nam {
                display: flex;
            }

            .left-name {
                text-align: center;
                width: 90%;
                // 文字溢出显示省略号
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .right-count {
                color: red;
            }
        }
    }
}

.item-tops {
    box-sizing: border-box;

    .pice-name {
        font-size: 23px;
        font-weight: bolder;
    }

    .pice-type {
        font-size: 20px;
        padding: 5px 10px;
        border-radius: 3px;
        background: #F74A4B;
        color: #fff;
        font-weight: bolder;
        margin-right: 10px;
    }
}

@import "./detail.scss";
</style>
