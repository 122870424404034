<template>
    <div>
        <div class="item-block" >
              <!-- <p class="desc ns-text-color" v-if="goodsSkuDetail.introduction">{{ goodsSkuDetail.introduction }}</p> -->
              <div class="item-tops" >
                <!-- <span class="pice-type" v-if="activeinfo.promotion_label">{{comactiveinfo.name}}专区</span> -->
                <span class="pice-name">{{ goodsSkuDetail.sku_name }}</span>
            </div>
            <div style="color: #82848a; margin-bottom: 10px;font-size: 11px;">
                    <span>已售</span>
                    <span>{{ goodsSkuDetail.sale_num }}{{ goodsSkuDetail.unit }}</span>
            </div>
            <div class="discount-banner "
             :style="{backgroundColor:comactiveinfo.bag}"
             v-if="activeinfo.promotion_label"
                >
                <div class="activity-name">
                    <!-- <i class="discount-icon iconfont iconicon_naozhong"></i>         -->
                     <img  style="margin-left:16px;" :src="comactiveinfo.iocn" alt="">
                    <span style="font-size: 20px;" >{{comactiveinfo.titlename}}</span>
                </div>
                <div class="surplus-time" v-if="goodsSkuDetail.promotion_type == 1 && discountTimeMachine.currentTime && addonIsExit.discount">
                    <span>{{ discountText }}</span>
                    <count-down class="count-down" v-on:start_callback="countDownS_cb()"
                        v-on:end_callback="countDownE_cb()" :currentTime="discountTimeMachine.currentTime"
                        :startTime="discountTimeMachine.startTime" :endTime="discountTimeMachine.endTime" :dayTxt="'天'"
                        :hourTxt="'小时'" :minutesTxt="'分钟'" :secondsTxt="'秒'"></count-down>

                    <!-- :tipText="'距离开始文字1'"
                    :tipTextEnd="'距离结束文字1'"
                    :endText="'结束自定义文字2'"-->
                </div>
                
            </div>
            <div class="promotion-price">
         
                <template v-if="activeinfo.promotion_label">
                    <!-- 活动类型价格显示 -->
                    <showPirceInfo :comactiveinfo="comactiveinfo" :token="token" :goodsSkuDetail="goodsSkuDetail">
                    </showPirceInfo>
                </template>
                <template v-else>
                    <!-- 非营销活动类型价格显示 -->
                    <notActivityPirce :goodsSkuDetail="goodsSkuDetail" :token="token"></notActivityPirce>
                </template>
                
                <!-- <div class="statistical">
                    <ul>
                        <li>
                            <p>累计评价</p>
                            <span>{{ goodsSkuDetail.evaluate }}</span>
                        </li>
                        <li>
                            <p>累计销量</p>
                            <span>{{ goodsSkuDetail.sale_num }}{{ goodsSkuDetail.unit }}</span>
                        </li>
                    </ul>
                </div> -->

                <!-- <div class="manjian-box">
                        <dl class="item-line manjian manjian-hide" v-if="addonIsExit.manjian && manjian.manjian_name">
                            <dt>满减</dt>
                            <dd>
                                <i class="i-activity-flag ns-text-color ns-border-color">{{ manjian.manjian_name }}</i>
                                <span v-if="manjian.manjian"><i class="i-activity-flag ns-text-color ns-border-color">满减</i></span>
                                <span v-if="manjian.mansong"><i class="i-activity-flag ns-text-color ns-border-color">满送</i></span>
                                <span v-if="manjian.free_shipping"><i class="i-activity-flag ns-text-color ns-border-color">包邮</i></span>
                            </dd>
                            <span class="manjian-open">展开促销<i class="el-icon-arrow-down"></i></span>
                        </dl>
                        <dl class="item-line manjian manjian-show" v-if="addonIsExit.manjian && manjian.manjian_name">
                            <dt>满减</dt>
                            <dd>
                                <span v-if="manjian.manjian"><i class="i-activity-flag ns-text-color ns-border-color">满减</i>{{ manjian.manjian }}</span></br>
                                <span v-if="manjian.mansong"><i class="i-activity-flag ns-text-color ns-border-color">满送</i>{{ manjian.mansong }}</span></br>
                                <span v-if="manjian.free_shipping"><i class="i-activity-flag ns-text-color ns-border-color">包邮</i>{{ manjian.free_shipping }}</span>
                            </dd>
                        </dl>
                    </div> -->
                    <dl class="mdoleDome-goods_attr_format" v-if="goodsSkuDetail.freeshipping&&goodsSkuDetail.freeshipping.length>0">
                <dt class="active-hd">免邮规则</dt>
                <dd><span v-for="(item,index) in goodsSkuDetail.freeshipping" :key="index">
                    {{item.name}}满{{item.price}}包邮;
                </span></dd>
            </dl>
            <dl class="mdoleDome-goods_attr_format" v-if="goodsSkuDetail.goods_limit > 0">
                <dt class="ns-text-color-gray active-hd">限购规则</dt>
                <dd>提示：本商品为限购商品，超过{{ goodsSkuDetail.goods_limit }}{{ goodsSkuDetail.unit }}恢复原价</dd>
            </dl>
            <dl v-if="activeinfo.promotion_info?.price_json&&activeinfo.promotion_info.price_json.length>0">
                    <dt class="active-hd">
                        批发规则
                    </dt>
                    <dd>
                        <span v-for="(item,index) in activeinfo.promotion_info.price_json" :key="index">
                        
                            {{item.num}} {{goodsSkuDetail.unit}}起批     
                             <span  style=" color: red;">
                                        <em >¥</em>
                                       <template v-if="token">
                                        {{goodsSkuDetail.sale_control?.view_price? item.price :goodsSkuDetail.sale_control.not_buy_msg}}
                                       </template>
                                       <template v-else>
                                         登录可见
                                       </template>
                           </span >;
                      </span>
                    </dd>
             </dl>
            <dl  v-if="goodsSkuDetail.get_free_goods !== null">
                <dt class="active-hd">换购规则</dt>
                <dd v-if="goodsSkuDetail.get_free_goods !== null">
                    <div class="getFreeBox">
                        <p>{{ goodsSkuDetail.get_free_goods.goods_range>0?('(可任选'+goodsSkuDetail.get_free_goods.goods_range+'种)'):'(可任选个数，无限制)' }}</p>
                        <div>
                            <template v-for="freeItem in returnFreeInfo">
                                <p v-if="freeItem.title!=''">{{ freeItem.title }}</p>
                                <p>{{ freeItem.text }}</p>
                            </template>
                        </div>
                    </div>
                </dd>
                <dd v-if="goodsSkuDetail.get_free_goods === null">无换购商品</dd>
            </dl>
            </div>
        </div>
    </div>
</template>

<script>
import showPirceInfo from "./compontens/showPirceInfo"
import notActivityPirce from './compontens/notActivityPirce.vue';
import CountDown from "vue2-countdown"
export default {
    components: {
        showPirceInfo,
        notActivityPirce,
		CountDown
	},
    props: {
        goodsSkuDetail: {
            type: Object,
            default: () => { },
        },
        token: {
            type: String,
            default: ''
        },
        goods_member_price: {
            type: Object,
            default: () => { }
        },
        activeinfo: {
            type: Object,
            default: () => { }
        },
        discountTimeMachine:{
            type:Object,
            default:()=>{}
        },
        addonIsExit:{
            type:Object,
            default:()=>{}
        },
        discountText:{
            type:String,
            default:''
        }

    },
    /**
     *   seckill   秒杀
     *   discount  特价
     *   wholesale 批发
     */
    computed: {
        // 活动信息数据进行处理
        comactiveinfo() {
            let updatedInfo = {};
            switch (this.activeinfo.promotion_label) {
                case 'seckill':
                    updatedInfo = {
                        name: "秒杀价",
                        price: this.activeinfo.promotion_info.price,
                        activity_price: this.activeinfo.promotion_info.seckill_price,
                        iocn:require('../../../assets/images/miao.png'),
                        titlename:'秒杀专区',
                        bag:'#F7547B'
                    }
                    break;
                case 'discount':
                    updatedInfo = {
                        name: "特价",
                        price: this.activeinfo.promotion_info.price,
                        activity_price: this.activeinfo.promotion_info.discount_price,
                        iocn:require('../../../assets/images/te.png'),
                         titlename:'限时特价',
                         bag:'#44B2FC'
                    }
                    break;
                case 'wholesale':
                    updatedInfo = {
                        name: "批发价",
                        price: this.goodsSkuDetail.price,
                        activity_price: this.activeinfo.promotion_info.min_price,
                        max_pice:this.activeinfo.promotion_info.max_price,
                        price_json: this.activeinfo.promotion_info.price_json,
                        iocn:require('../../../assets/images/pi.png'),
                         titlename:'批发专区',
                          bag:'#F88B57'
                    }
                    break;
            }
            return updatedInfo
        },
        returnFreeInfo(){
            if(this.goodsSkuDetail.get_free_goods!=null){
                let newObj = this.goodsSkuDetail.get_free_goods;
                let newArr = [];
                let isLadder = (newObj.is_ladder===0?false:true);
                if(newObj.getFreeGoods!=null){
                    newObj.getFreeGoods.forEach(item => {
                        if(isLadder){
                            let strArr = [];
                            item.ladder_json = (typeof item.ladder_json==='string')?JSON.parse(item.ladder_json):item.ladder_json;
                            item.ladder_json.forEach(item1 => {
                                strArr.push(`购买本商品x${item1.mainRang}件，可换购x${item1.rang}件`);
                            });
                            newArr.push({title:`${item.goods.goods_name}：`,text:strArr.join(';')});
                        }else{
                            let str = `购买本商品x${item.count}件，可换购${item.goods.goods_name}x${item.num}件`;
                            newArr.push({title:'',text:str});
                        }
                    });
                    return newArr;
                }else{
                    return [];
                }
            }
            return []
        }
    },
    methods:{
        countDownE_cb(){
            this.$emit('countDownE_cb')
        },
        countDownS_cb(){
            this.$emit('countDownS_cb')
        }
    }
}
</script>

<style lang="scss" scoped>
.promotion-price{
    padding: 20px 0 10px 0;
    background: #F3F3F3;
}

.active-hd{
    display: inline-block;
    min-width: 88px;
    height: 35px;
    line-height: 35px;
    text-align-last : justify !important;
    padding-left: 16px;
    font-size: 14px;
    margin-right: 16px;
    color: #2c2626e0;
    font-weight: 600;
   
}
dd{
    padding-left: 16px;
}
.getFreeBox{
    display: flex;
    flex-direction: column;
    gap: 6px;
    p{
        color: #ff547b;
        line-height: 1rem;
    }
    >div{
        display: flex;
        flex-direction: column;
        gap: 6px;
    }
}

</style>