<template>
    <!-- :style=" showDome ? 'min-height: 165px;' : 'height: 165px;'" -->
    <div class="mdoleDome-box" style="min-height: 165px;">
        <div class="mdoleDome">
            <dl class="mdoleDome-top">
                <dt>优惠券</dt>
                <div class="show-box" :style="isdiscounts ? 'min-height: 30px;' : 'height: 35px;'">
                    <dd class="concessionary">
                        <span v-for="(item, index) in couponList" :key="index"
                            @click="receiveCoupon(item.coupon_type_id)" class="top-box">
                            <span class="top-titl">
                                <!-- 满减v-if="item.type == 'reward'"  折扣v-if="item.type == 'discount'" -->
                                <span class="ingo" v-if="item.discount > 0">满{{ item.at_least }}享{{ item.discount
                                    }}折</span>
                                <span class="ingo" v-if="item.money > 0">满{{ item.at_least }}减{{ item.money }}</span>
                                <i class="i-1" v-if="item.discount > 0">满{{ item.at_least }}享{{ item.discount }}折</i>
                                <i class="i-1" v-if="item.money > 0">满{{ item.at_least }}减{{ item.money }}</i>
                            </span>

                            <span class="top-lable" v-if="item.is_lingqu">{{ item.is_lingqu ? '已领取' : '领取' }}</span>
                        </span>
                    </dd>
                    <i class="examine" @click="ondiscounts" v-if="couponList.length > 3">查看更多</i>
                </div>
            </dl>
            <template v-for="item in returnShopClass()">
                <dl class="mdoleDome-goods_attr_format">
                    <dt>{{ item.name }}</dt>
                    <dd>{{ item.value }}</dd>
                </dl>
            </template>
            <!-- <div class="mdoleDome-yuan">
            <dt>原价</dt>
            <dd>
                <em>¥</em>
                <del><template v-if="token">
                        <template v-if="goodsSkuDetail.sale_control?.view_price">
                            {{ goodsSkuDetail.price }}<span style="font-size: 12px;">/{{ goodsSkuDetail.unit }}</span>
                        </template>
</template>
<template v-else>
                        {{ storeWithoutAuthority }}
                    </template>
</del>
</dd>
</div> -->
            <div class="mdoleDome-jian" v-if="manjian.manjian_name">
                <dt>满减活动</dt>
                <dd>
                    <span>{{ manjian.manjian_name }}</span>
                </dd>
            </div>

            <div class="mdoleDome-discounts" v-if="manjians.length > 0">
                <dt>满减</dt>
                <span class="preferential" v-for="(item, index) in manjians" :key="index">{{ item }}</span>
            </div>
            <div class="mdoleDome-discounts" v-if="manjian.mansong != undefined">
                <dt>满送</dt>
                <dd>
                    <span style="border-radius:0px">{{ manjian.mansong }}</span>
                </dd>
            </div>
            <dl class="mdoleDome-goods_attr_format" v-if="goodsSkuDetail.is_virtual == 0">
                <dt>运费</dt>
                <dd>
                    <i class="i-activity-flag ns-text-color ns-border-color bina"
                        v-if="goodsSkuDetail.is_free_shipping">快递免邮</i>
                    <i class="i-activity-flag ns-text-color ns-border-color bina" v-else>快递不免邮</i>
                </dd>
            </dl>
        </div>
        <!-- <div class="mdoleDome-location" @click="showoclik">
                查看更多
           
       </div> -->
        <!-- <div class="mdoleDome-rigt">
            <dl class="mdoleDome-goods_attr_format">
                <dt style="color:#000000a1;font-weight: 600;">商品属性</dt>
                <dd></dd>
            </dl>
            <template v-if="returnShopClass().length > 0">
                <dl class="mdoleDome-goods_attr_format" v-for="item in returnShopClass()">
                    <template>
                        <dt>{{ item.name }}</dt>
                        <dd>{{ item.value }}</dd>
                    </template>
                </dl>
            </template>
        </div> -->
    </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    props: {
        manjian: {
            type: Object,
            default: () => { }
        },
        couponList: {
            type: Array,
            default: () => []
        },
        goodsSkuDetail: {
            type: Object,
            default: () => { }
        },
        manjians: {
            type: Array,
            default: () => []
        },
        activeinfo:{
            type:Object,
            default:()=>{}
        }
    },
    data: () => {
        return {
            showDome: false,
            isdiscounts: false,
            couponBtnSwitch: false
        }
    },
    computed: {
        ...mapGetters(["token", "siteInfo", "defaultHeadImage", "defaultShopImage", "defaultGoodsImage", "addonIsExit", "locationRegion", "storeWithoutAuthority"])
    },
    methods: {
        showoclik() {
            this.showDome = !this.showDome
        },
        ondiscounts() {
            this.isdiscounts = !this.isdiscounts
        },
        receiveCoupon(id) {
            this.$emit('receiveCoupon', id)
        },
        returnShopClass(){
      
      if(!this.goodsSkuDetail){
         return [];
      }
      let detail = this.goodsSkuDetail;
      let is_medicinal = detail.is_medicinal;
    
      let arr = [
        { name : (is_medicinal===0?'库存：':'产地：') ,value:(is_medicinal===0?detail.stock_name:detail.attr.attr_place)},
        { name : '单位：' ,value:detail.unit},
        { name : '商品批号：' ,value:detail.attr.attr_batch_number},
        { name : '生产日期：' ,value:detail.attr.attr_production_date},
        { name : '效期：',value:detail.attr.attr_validity},
        { name : '保质期：',value:detail.attr.attr_shelf_life},
        { name : '生产厂家：' ,value:detail.attr.attr_factory},
        { name : '规格：' ,value:detail.attr.attr_specs},
        { name : '商品规格：' ,value:detail.attr.attr_goods_attr},
        { name : 'ERPID：',value:detail.goods_erp_spid}

        // { name : '批准文号：' ,value:detail.attr.attr_approval_number},
        // { name : '剂型：' ,value:detail.attr.attr_dosage_form},
        // { name : '中包装：' ,value:detail.min_buy},
        // { name : '国家码：' ,value:detail.attr.attr_country_code},
        // { name : '件装量：' ,value:detail.packsize},
        // { name : '是否GSP管控：' ,value:detail.gspflag ? "是" : "否"},
        // { name : '器械子类：' ,value:detail.qixie_zilei},
      ];
      arr = arr.filter(item => item.value!==''&& item.value!==null&& item.value!==undefined);

      return arr;
    }
    }
}
</script>

<style lang="scss" scoped>
.mdoleDome-box {
    // position: relative;
    padding: 0px 20px 10px 16px;
    // overflow: hidden;
    display: flex;

    .mdoleDome {
        width: 440px;

        dt {
            display: inline-block;
            min-width: 65px;
            height: 35px;
            line-height: 35px;
            text-align-last: justify;
            font-size: 12px;
            margin-right: 16px;
            flex-shrink: 0;
            color: #303133ad;
        }

        dd {
            display: inline-block;
            vertical-align: bottom;
            line-height: 35px;
            color: #222222;
            font-size: 12px;
            .bina {
                border: 1px solid #FB3E5E;
                padding: 2px 5px;
                border-radius: 3px;

            }
        }

        &-top {
            display: flex;
            overflow: hidden;
             margin-top: 15px;
            .show-box {
                padding-top: 5px;
                width: 275px;
                position: relative;
            .concessionary{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
                .top-box {
                    position: relative;
                    line-height: 44px;

                    .top-lable {
                        position: absolute;
                        right: 11px;
                        top: -5px;
                        font-size: 10px;
                        background: rgba(255, 84, 123, 0.5490196078);
                        line-height: 15px;
                        padding: 0px 5px 0px 5px;
                        color: #fff;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        font-weight: 500;
                    }
                }
            }

            .examine {
                position: absolute;
                top: 4px;
                right: -70px;
                padding: 0px 10px;
                font-size: 11px;
                background: rgb(251 62 94 / 49%);
                ;
                border-radius: 3px;
                border: 2px solid #ff547b;
                color: #fff;
                cursor: pointer;

                &:hover {
                    background: rgb(251 62 94 / 62%);
                }
            }

            .top-titl {
                position: relative;
                line-height: 35px;
                cursor: pointer;

                .ingo {
                    width: 100px;
                    padding: 5px 15px;
                    font-size: 12px;
                    margin-right: 10px;
                    cursor: pointer;
                    background-color: #ff547b;
                    -webkit-mask-box-image: url("../../../assets/images/goods/coupon.png");
                }

                .i-1 {
                    position: absolute;
                    color: #ff547b;
                    left: 10px;
                    top: -9px;
                }
            }
        }

        &-jian {
            span {
                padding: 2px 5px;
                border-radius: 4px;
                color: #ff547b;
                border: 1px solid #ff547b;
            }

            &-yuan {
                display: flex;
            }


        }

        &-discounts {
            span {
                margin-right: 10px;
                border-radius: 5px;
                display: inline-block;
                padding: 0px 5px;
                background: red;
                color: #fff;
            }

        }

        &-location {
            position: absolute;
            right: 50px;
            bottom: 0;
        }
    }

    .mdoleDome-rigt {
        padding-top: 15px;
        dt {
            display: inline-block;
            min-width: 65px;
            height: 35px;
            line-height: 35px;
            text-align-last: justify;
            font-size: 12px;
            margin-right: 16px;
            flex-shrink: 0;
            color: #303133ad;
        }

        dd {
            display: inline-block;
            vertical-align: bottom;
            line-height: 35px;
            color: #222222 ;
            font-size: 12px;
        }
    }

}
</style>
