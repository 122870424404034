<template>
    <div class="buy-number">
        <dl class="item-line">
            <dt>数量</dt>
            <dd>
                <div class="num-wrap">
                    <el-input v-model="internalValue" placeholder="0" @input="keyInput()" @keypress="onlyNumber($event)"></el-input>
                    <div class="operation">
                        <span class="increase el-icon-caret-top" @click="changeNum('+')"></span>
                        <span class="decrease el-icon-caret-bottom" @click="changeNum('-')"></span>
                    </div>
                </div>
                <span class="inventory" v-if="goodsSkuDetail.stock > 100">库存：充足</span>
                <span class="inventory" v-if="goodsSkuDetail.stock < 100">库存：紧张</span>
                <!-- <span class="unit">{{ goodsSkuDetail.unit }}</span> -->
                <!-- 限购 -->
                <!-- <em>(每人限购1件)</em> -->
            </dd>
        </dl>
                 <div class="info-title">
                <dd v-if="goodsSkuDetail.min_buy > 1" style="padding-left: 10px; color: #4caf50">
                    提示：当前商品需以{{ goodsSkuDetail.min_buy }}的倍数购买
                    <span v-if="internalValue % goodsSkuDetail.min_buy === 0">--当前符合购买规则！</span>
                    <span v-else style="color: #ff0036">--当前不符合购买规则！</span>
                </dd>
                <dd v-if="goodsSkuDetail.goods_limit > 0" style="padding-left: 10px; color: #ff547b !important">
                    <span v-if="internalValue < goodsSkuDetail.goods_limit">提示：当前未超过限购数量！</span>
                    <span v-if="internalValue === goodsSkuDetail.goods_limit">提示：即将超过限购数量{{ goodsSkuDetail.goods_limit }}{{
                        goodsSkuDetail.unit }}</span>
                    <span v-if="internalValue > goodsSkuDetail.goods_limit"> 提示：当前超过限购数量{{ internalValue - goodsSkuDetail.goods_limit
                        }}{{
                            goodsSkuDetail.unit }}超出数量为原价！ </span>
                </dd>
                <dd v-if="goodsSkuDetail.get_free_goods !== null" style="margin-left: 10px">
                    <span>提示：换购赠送商品</span><span style="color: #4ccf6e">(任选{{ goodsSkuDetail.get_free_goods?.goods_range
                        }}种)</span>
                    <span v-show="internalValue < goodsSkuDetail.get_free_goods?.count"> ，当前不符合换购活动购买数量(如需请增加购买数量) </span>
                    <span v-show="internalValue >= goodsSkuDetail.get_free_goods?.count">当前符合换购条件</span>
                </dd>
       </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: Number,
                default: 1
            },
            goodsSkuDetail: {
                type: Object,
                default: () => ({})
            },
        },
        data() {
            return {
                internalValue: this.value
            };
        },
        watch: {
            value(newVal) {
                this.internalValue = newVal;
            }
        },
        methods: {
            keyInput() {
                this.internalValue = this.internalValue.replace(/\D/g, '');
                this.$emit('input', parseInt(this.internalValue));
            },
            changeNum(tag) {
                if (this.goodsSkuDetail.stock == 0) return;

                var stock = this.goodsSkuDetail.stock;
                var min = 1;

                if (tag === "+") {
                    if (this.internalValue < stock) {
                        if (this.goodsSkuDetail.min_buy > 1) {
                            var minbuysl = this.goodsSkuDetail.min_buy;
                            var Startingpurchase = this.internalValue + minbuysl;
                            this.internalValue = Startingpurchase;
                        } else {
                            this.internalValue++;
                        }
                    } else {
                        return;
                    }
                } else if (tag === "-") {
                    if (this.internalValue > min) {
                        if (this.goodsSkuDetail.min_buy > 1) {
                            var minbuysl = this.goodsSkuDetail.min_buy;
                            var Startingpurchase = this.internalValue - minbuysl;
                            this.internalValue = Startingpurchase;
                        } else {
                            this.internalValue -= 1;
                        }
                    } else {
                        return;
                    }
                }
                this.$emit('input', this.internalValue);
            }
        }
    };
</script>

<style lang="postcss" scoped>
</style>








