<template>
 <div>

         <dl class="item-line">
                   <dt class="titl-name" style="color: #ee443f;">{{comactiveinfo.name}}</dt>
                            <dd>
                                <em class="yuan ns-text-color">¥</em>
                                <template v-if="token">
                                    <template v-if="goodsSkuDetail.sale_control?.view_price">
                                       <template v-if="comactiveinfo.max_pice">
                                        <span class="price ns-text-color" v-if="comactiveinfo.activity_price>=comactiveinfo.max_pice">
                                            {{comactiveinfo.activity_price}}
                                            <!-- <span class="price-unit">/{{goodsSkuDetail.unit}}</span> -->
                                        </span>
                                        <span class="price ns-text-color" v-else>
                                            {{comactiveinfo.activity_price}} ~ <em class="yuan ns-text-color">¥ </em>{{comactiveinfo.max_pice}}
                                            <!-- <span class="price-unit">/{{goodsSkuDetail.unit}}</span> -->
                                        </span>
                                       </template>
                                       <template v-else>
                                        <span class="price ns-text-color">
                                            {{comactiveinfo.activity_price}}
                                            <!-- <span class="price-unit">/{{goodsSkuDetail.unit}}</span> -->
                                        </span>
                                       </template>

                                    </template>
                                    <template v-else>
                                        {{goodsSkuDetail.sale_control?.not_buy_msg}}
                                    </template>
                                </template>
                                <template v-else>
                                    <span class="price ns-text-color" @click="$router.push('/login')">登录可见</span>
                                </template>
                             
                                
             </dd>
       </dl>
       <dl  class="item-line">
        <dt  class="titl-name">原价</dt>
        <dd>
            <del style=" color: #969696 ;">
                  <template v-if="token">
                        <template v-if="goodsSkuDetail.sale_control?.view_price">
                           <span style="color: #969696 ; font-size: 14px;"><em>¥</em>{{ goodsSkuDetail.price }}</span>
                            <!-- <span style="font-size: 12px;">/{{ goodsSkuDetail.unit }}</span> -->
                        </template>
                        <template v-else>
                             {{goodsSkuDetail.sale_control.not_buy_msg}}
                        </template>
                 </template>
                    <template v-else>
                      <span style="color: #969696 ; font-size: 14px;">  <em>¥</em>
                        {{ storeWithoutAuthority }}</span>
                    </template>
                </del>
            </dd>
     </dl>
     <dl  class="item-line" v-if="goodsSkuDetail.market_price>0">
        <dt  class="titl-name">建议零售价</dt>
        <dd>
                  <template v-if="token">
                        <template v-if="goodsSkuDetail.sale_control?.view_price">
                           <span style="color: #303133 ; font-size: 14px;"><em>¥</em>{{ goodsSkuDetail.market_price }}</span>
                            <!-- <span style="font-size: 12px;">/{{ goodsSkuDetail.unit }}</span> -->
                        </template>
                        <template v-else>
                             {{goodsSkuDetail.sale_control.not_buy_msg}}
                        </template>
                 </template>
                    <template v-else>
                      <span style="color: #303133 ; font-size: 14px;">  <em>¥</em>
                        {{ storeWithoutAuthority }}</span>
                    </template>
            </dd>
     </dl>


 </div>
</template>

<script>
    export default {
     props:{
        comactiveinfo:{
            type:Object,
            default:()=>{}
        },
        goodsSkuDetail:{
            type:Object,
            default:()=>{}
        },
        token:{
            type:String,
            default:''
     }
    }
    }
</script>

<style lang="scss" scoped>
.titl-name{
    min-width: 88px;
    font-size: 16px !important;
}
.yuan{
    font-size: 28px !important;

}
</style>
