<template>
 <div>
    <dl class="item-line buy-btn">
        <dt></dt>
        <dd>
            <el-button
                :type="disposeData.type"
                @click.stop="joinCart(disposeData.url)"
                :disabled="disposeData.disabled"
                :plain="disposeData.plain"
            >
                {{ disposeData.name }}
            </el-button>
        </dd>
    </dl>

    <dl class="item-line merchant-service" v-show="service_list.length">
                <dt>商品服务</dt>
                <div>
                    <dd v-for="item in service_list">
                        <i class="el-icon-success"></i>
                        <span class="ns-text-color-gray" :title="item.service_name">{{ item.service_name }}</span>
                    </dd>
                </div>
     </dl>

 </div>
</template>

<script>
export default {
    props: {
        goodsSkuDetail: {
            type: Object,
            default: () => { }
        },
        token: {
            type: String,
            default: ''
        },
        buynowdisabled: {
            type: Boolean,
            default: false
        },
        number: {
            type: Number,
            default: 1
        },
        btnSwitch: {
            type: Boolean,
            default: false
        },
        service_list:{
            type: Array,
            default: () => []
        }
    },
    
    computed: {
        disposeData() {
            if (!this.token) {
                return { name: '登录可见', url: '/login', type: 'primary', disabled: false, plain: false };
            }
            if (this.goodsSkuDetail.goods_state != 1) {
                return { name: '该商品已下架', url: '', type: 'info', disabled: true, plain: true };
            }
            if (this.goodsSkuDetail.stock == 0) {
                return { name: '库存不足', url: '', type: 'info', disabled: true, plain: true };
            }
            if (this.goodsSkuDetail.max_buy != 0 && this.goodsSkuDetail.purchased_num >= this.goodsSkuDetail.max_buy) {
                return { name: '已达最大限购数量', url: '', type: 'info', disabled: true, plain: true };
            }
            if (this.goodsSkuDetail.is_virtual == 0 && this.goodsSkuDetail.sale_control?.is_member_by) {
                return { name: '加入购物车', url: 'add', type: 'primary', disabled: this.buynowdisabled, plain: false };
            }
            return {
                name: this.goodsSkuDetail.sale_control?.not_buy_msg || '暂无权限',
                url: '',
                type: 'info',
                disabled: true,
                plain: true
            };
        }
    },
    methods: {
        joinCart(url) {
            switch(url){
             case  'add' :
             this.$emit("joinCart")
             break;
             case  '/login':
             this.$router.push(url)
             break ;
            }
        },
    }
}
</script>

<style lang="postcss" scoped></style>