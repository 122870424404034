<template>
    <div class="activeks">
        <div class="activeks-adheaders">
            <span class="singn"></span>
            <span class="title-name">组合套餐</span>
        </div>
        <div class="activeks-box" v-for="(item, index) in bundling" :key="index">
            <div style="margin-top: 10px;">主商品 <span style="margin-left: 160px;">换购商品 <span class="zogn">种类：{{
           item.bundlingGoods.length }}</span></span></div>
         <div class="activeks-mian">
            <div class="activeks-time">
                <div class="activeks-main">
                    <img :src="$img(goodsSkuDetail.sku_image, { size: 'mid' })" alt="">
                    <p class="box-name">{{ goodsSkuDetail.sku_name }}</p>
                    <ctrlShowText :info="goodsSkuDetail" type="custom"  size="12px"  gap="10px" color="#909399"
                    :wesArr="['规格','效期','厂家']" :traArr="['规格','生产日期','厂家']"></ctrlShowText>
                    <p class="box-price">￥{{ goodsSkuDetail.sale_control?.is_member_by &&
                        goodsSkuDetail.sale_control?.view_price ? minGoodsBundling :
                        goodsSkuDetail.sale_control?.not_buy_msg }}</p>
                </div>
                <span class="add">
                    +
                </span>
                <div class="moke-list" :ref="'bundlingMoke' + index" @mousedown="startScroll(index, $event)"
                    @mouseup="stopScroll" @mouseleave="stopScroll" @mousemove="onScroll(index, $event)">
                    <div class="activeks-list">
                        <div class="activeks-moke" v-for="(goods, goods_index) in item.bundlingGoods" :key="goods_index"
                            @click="$router.pushToTab({ path: '/sku-' + goods.sku_id })">
                            <img :src="$img(goods.sku_image, { size: 'mid' })" alt="">
                            <p class="box-name">{{ goods.sku_name }}</p>
                            <ctrlShowText :info="goods" type="custom"  size="12px"  gap="10px" color="#909399"
                            :wesArr="['规格','效期','厂家']" :traArr="['规格','生产日期','厂家']"></ctrlShowText>
                            <p class="box-price">￥{{ item.sale_control?.is_member_by && item.sale_control?.view_price ?
                                goods.promotion_price : item.sale_control?.not_buy_msg}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="activeks-rgint">
                <h4>组合套餐价</h4>
                <template v-if="$store.getters.token">
                    <template v-if="item.sale_control?.is_member_by && item.sale_control?.view_price">
                        <del>原价：{{ item.goods_money }}元</del>
                        <p class="rgint-pirce">￥{{ item.bl_price }}</p>
                    </template>
                    <template v-else>
                        <del>{{ item.sale_control?.not_buy_msg }}</del>
                        <p class="rgint-pirce" style="font-size: 18px">￥{{ item.sale_control?.not_buy_msg }}</p>
                    </template>
                </template>
                <template v-else>
                    <del>原价：登录可见</del>
                    <p class="rgint-pirce" style="font-size: 18px">￥登录可见</p>
                </template>
                <template v-if="$store.getters.token">
                    <div class="rgint-add"
                        :class="item.sale_control && item.sale_control?.view_price ? 'rgint-add' : 'ban'"
                        @click="joinCart(item)">
                        <span class="into">加入购物车</span>
                    </div>
                </template>
                <template v-else>
                    <div class="rgint-add" @click="joinCart(item)">
                        <span class="into">加入购物车</span>
                    </div>
                </template>
            </div>
         </div>
         
        </div>
    </div>
</template>

<script>
import { onTip } from "@/utils/tips"
import { joinCart } from "@/api/promotion/bundling"
import ctrlShowText from "@/components/ctrl-show-text/ctrl-show-text.vue";
export default {
    name: 'showActivity',
    components: {
        ctrlShowText
    },
    props: {
        bundling: {
            type: Array,
            default: () => []
        },
        goodsSkuDetail: {
            type: Object,
            default: () => { }
        },
        addonIsExit: {
            type: Object,
            default: () => { }
        },
        token: {
            type: String,
            default: ''
        },
        minGoodsBundling: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            number: 1,
            isScrolling: false,
            startX: 0,
            scrollLeft: 0,
        }
    },
    computed: {
    },
    methods: {
        // 加入购物车
        joinCart(item) {
            if (onTip(item.sale_control?.view_price, item.sale_control?.is_member_by, this, item.sale_control?.not_buy_msg)) return
            joinCart({
                id: item.bl_id,
                num: this.number
            }).then((res) => {
                if (res.code === 0) {
                    this.$message({
                        message: "加入购物车成功",
                        type: "success"
                    })
                }
            }).catch((err) => {
                if (err.message === "token不存在") {
                    this.$router.pushToTab("/login")
                } else {
                    this.$message.error(err.message)
                }
            })
        },
        startScroll(index, e) {
            this.isScrolling = true;
            const tabsContainer = this.$refs['bundlingMoke' + index][0];
            this.startX = e.pageX - tabsContainer.offsetLeft;
            this.scrollLeft = tabsContainer.scrollLeft;
        },
        stopScroll() {
            this.isScrolling = false;
        },
        onScroll(index, e) {
            if (!this.isScrolling) return;
            e.preventDefault();
            const tabsContainer = this.$refs['bundlingMoke' + index][0];
            if (tabsContainer) {
                const x = e.pageX - tabsContainer.offsetLeft;
                const walk = (x - this.startX) * 2; // 调整滚动速度
                tabsContainer.scrollLeft = this.scrollLeft - walk;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.activeks {
    clear: both;

    .moke-list {
        moz-user-select: none;
        /*火狐*/
        -webkit-user-select: none;
        /*webkit浏览器*/
        -ms-user-select: none;
        /*IE10*/
        -khtml-user-select: none;
        /*早期浏览器*/
        user-select: none;
        width: 480px;
        overflow-x: auto;
        display: flex;
        cursor: pointer;
    }

    .activeks-list {
        display: flex;
    }

    &-adheaders {
        padding: 5px 10px;
        background: #F7F7F7;

        .singn {
            display: inline-block;
            height: 15px;
            margin-right: 5px;
            width: 5px;
            background: #4caf50;
        }

        .title-name {
            font-size: 20px;
            font-weight: bold;
        }
    }

    .add {
        margin: 80px 15px;
        font-size: 30px;
        font-weight: bold;
        color: #808080;
    }

    &-box {
        .activeks-mian{
            display: flex;
        justify-content: space-between;
        padding: 0 10px;
        }
        .activeks-main {
            margin-top: 10px;
        }

        .activeks-time {
            margin-top: 10px;
            display: flex;

            .activeks-moke {
                width: 147px;
                margin: 10px 0;
                margin-right: 20px;
            }

            img {
                margin-top: 10px;
                width: 147px;
                height: 147px;
            }

            p {
                font-size: 12px;
                color: #808080;
                margin-top: 10px;
            }

            .box-price {
                font-weight: bolder;
                font-size: 14px;
                color: red;
            }

            .zogn {
                color: #808080;
                font-size: 12px;
            }
        }

        .activeks-rgint {
            margin-top: 30px;
            width: 147px;
            text-align: center;
            background-image: linear-gradient(181.2deg, #4caf50 2.5%, rgb(254, 254, 254) 45.8%);
            cursor: pointer;

            h4 {
                font-size: 16px;
                color: #fff;
                margin: 10px 0 30px 0;
            }

            del {
                font-size: 12px;
                color: #b0b0b0;
            }

            p {
                font-size: 25px;
                color: red;
                font-weight: bolder;
                margin-bottom: 25px;
            }

            .rgint-add {
                .into {
                    cursor: pointer;
                    width: 80px;
                    border: 1px solid #4caf50;
                    padding: 8px 10px;
                    border-radius: 5px;
                    color: #4caf50;
                }
            }

            .ban {
                .into {
                    cursor: not-allowed;
                    width: 80px;
                    border: 1px solid #dddddd6e;
                    background: #dddddd6e;
                    padding: 8px 10px;
                    border-radius: 5px;
                    color: white;
                }
            }
        }
    }
    .factory {
        width: 150px;
        line-height: 18px;
        height: 33px;
        word-wrap: break-word;
        word-break: break-word;
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .box-name {
        width: 150px;
        height: 35px;
        line-height: 18px;
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-weight: bolder;
    }
    ::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: #f9f9f9;


    }

    ::-webkit-scrollbar-thumb {
        background-color: #4caf50;
        border-radius: 10px;
        transition: background-color 0.2s ease;


    }

    // ::-webkit-scrollbar-button:start:decrement,
    // ::-webkit-scrollbar-button:end:increment  {


    //     display: block;


    //     height: 10px;


    //     background-color: #ddd;


    // }


}
</style>
