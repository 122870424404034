<template>
    <div class="activeks">
        <div class="activeks-adheaders">
            <span class="singn"></span>
            <span class="title-name">换购专区</span>
        </div>
        <div style="margin-top: 10px;">主商品 <span style="margin-left: 160px;">换购商品 <span class="zogn">种类：{{
            goodsSkuDetail.get_free_goods?.getFreeGoods.length }}</span></span></div>
        <div class="activeks-box">
            <div class="activeks-time">
                <div class="activeks-main">
                    <img :src="$img(goodsSkuDetail.sku_image, { size: 'mid' })" alt="">
                    <p class="box-name">{{ goodsSkuDetail.sku_name }}</p>
                    <ctrlShowText :info="goodsSkuDetail" type="custom"  size="12px"  gap="10px" color="#909399"
                    :wesArr="['规格','效期','厂家']" :traArr="['规格','生产日期','厂家']"></ctrlShowText>
                    <template v-if="$store.getters.token">
                        <p class="box-price">￥{{ goodsSkuDetail.sale_control?.is_member_by &&
                            goodsSkuDetail.sale_control?.view_price ? goodsSkuDetail.price :
                            goodsSkuDetail.sale_control?.not_buy_msg }}</p>
                    </template>
                    <template v-else>
                        <p class="box-price">￥登录可见</p>
                    </template>
                </div>
                <span class="add">
                    +
                </span>
                <div class="moke-list" ref="activeksContainer" @mousedown="startScroll" @mouseup="stopScroll"
                    @mouseleave="stopScroll" @mousemove="onScroll">
                    <div class="activeks-list">
                        <div class="activeks-moke"
                            v-for="(exchangeitem, exchangeindex) in goodsSkuDetail.get_free_goods?.getFreeGoods"
                            :key="exchangeindex" @click="$router.pushToTab({ path: '/sku-' + exchangeitem.goods_id })">

                            <img :src="$img(exchangeitem.goods.goods_image, { size: 'mid' })" alt="">
                            <p class="box-name factory-t">{{ exchangeitem.goods.goods_name }}</p>
                            <ctrlShowText :info="exchangeitem" type="custom"  size="12px"  gap="10px" color="#909399"
                            :wesArr="['规格','效期','厂家']" :traArr="['规格','生产日期','厂家']"></ctrlShowText>
                            <template v-if="$store.getters.token">
                                <p class="box-price">￥{{ exchangeitem.sale_control?.is_member_by &&
                                    exchangeitem.sale_control?.view_price ?
                                    exchangeitem.price : exchangeitem.sale_control?.not_buy_msg }}</p>
                            </template>
                            <template v-else>
                                <p class="box-price">￥登录可见</p>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="getfree-b" v-if="goodsSkuDetail.get_free_goods !== null">
            <p>换购活动：以下产品可任选{{ goodsSkuDetail.get_free_goods.goods_range }}种</p>
            <p v-for="(exitem, index) in goodsSkuDetail.get_free_goods.getFreeGoods" style="font-size:12px; color:#31bb6d ;"
                :key="index">
                每满{{ exitem.count }}{{ goodsSkuDetail.unit }} 可加 {{ exitem.price }} 换购 “{{ exitem.goods.goods_name }}”
                x {{ exitem.num }} ,最多{{ exitem.limit }}个
            </p>
        </div>
        </div>
    </div>
</template>

<script>
import { onTip } from "@/utils/tips"
import { joinCart } from "@/api/promotion/bundling"
import ctrlShowText from "@/components/ctrl-show-text/ctrl-show-text.vue";
export default {
    name: 'showActivity',
    components: {
        ctrlShowText
    },
    props: {
        goodsSkuDetail: {
            type: Object,
            default: () => { }
        },
        token: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            number: 1,
        }
    },
    computed: {
        showpirce() {

        }
    },
    methods: {
        // 加入购物车
        joinCart(item) {
            if (onTip(item.sale_control?.view_price, item.sale_control?.is_member_by, this, item.sale_control?.not_buy_msg)) return
            if (!this.goodsSkuDetail.get_free_goods?.getFreeGoods.length > 0) {
                this.$message({
                    message: "暂无副商品",
                    type: "error"
                })
                return
            }
            this.$store
                .dispatch("cart/add_to_cart", {
                    site_id: item.site_id,
                    sku_id: item.sku_id,
                    num: this.number
                })
                .then(res => {
                    if (res.code === 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch(err => {
                    if (err.message === "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })
        },
        stopirce(goodsSkuDetail) {
            let minCount = Infinity; // 初始化为正无穷大，用于寻找最小值
            let minPrice = Infinity; // 初始化为正无穷大，用于寻找最小值
            // 换购商品的主商品个数
            let cont = goodsSkuDetail.get_free_goods.count > 0 ? goodsSkuDetail.get_free_goods.count : 1
            goodsSkuDetail.get_free_goods?.getFreeGoods.forEach((item, index) => {
                if (item.count < minCount || (item.count === minCount && item.price < minPrice)) {
                    minCount = item.count;
                    minPrice = item.price;
                }
            });
            let price = goodsSkuDetail.get_free_goods?.getFreeGoods.length > 0 ? (Number(goodsSkuDetail.price * cont) + Number(minPrice)).toFixed(2) : '暂无副商品'

            return price
        },
        startScroll(e) {
            this.isScrolling = true;
            this.startX = e.pageX - this.$refs.activeksContainer.offsetLeft;
            this.scrollLeft = this.$refs.activeksContainer.scrollLeft;
        },
        stopScroll() {
            this.isScrolling = false;
        },
        onScroll(e) {
            if (!this.isScrolling) return;
            e.preventDefault();
            const x = e.pageX - this.$refs.activeksContainer.offsetLeft;
            const walk = (x - this.startX) * 2; // 滚动速度
            this.$refs.activeksContainer.scrollLeft = this.scrollLeft - walk;
        },
    }
}
</script>

<style lang="scss" scoped>
.activeks {
    clear: both;

    .moke-list {
        moz-user-select: none;
        /*火狐*/
        -webkit-user-select: none;
        /*webkit浏览器*/
        -ms-user-select: none;
        /*IE10*/
        -khtml-user-select: none;
        /*早期浏览器*/
        user-select: none;
        width: 480px;
        overflow-x: auto;
        display: flex;
        cursor: pointer;
    }

    .activeks-list {
        display: flex;
    }

    .zogn {
        color: #808080;
        line-height: 20px;
        font-size: 12px;
    }

    &-adheaders {
        padding: 5px 10px;
        background: #F7F7F7;

        .singn {
            display: inline-block;
            height: 15px;
            width: 5px;
            margin-right: 5px;
            background: #bf54ff;
        }

        .title-name {
            font-size: 20px;
            font-weight: bold;
        }
    }

    .add {
        margin: 80px 15px;
        font-size: 30px;
        font-weight: bold;
        color: #808080;
    }

    &-box {
        display: flex;
        justify-content: space-between;
        padding: 10px 10px;

        .activeks-main {
            margin-top: 10px;
        }

        .activeks-moke {
            margin-right: 20px;
        }

        .activeks-time {
            display: flex;

            img {
                width: 147px;
                height: 147px;
            }

            p {
                font-size: 12px;
                color: #808080;
            }

            .box-price {
                font-weight: bolder;
                font-size: 14px;
                color: red;
            }

        }

        .activeks-rgint {
            margin-top: 20px;
            padding: 10px;
            min-width: 147px;
            text-align: center;
            background-image: linear-gradient(181.2deg, #bf54ff 2.5%, rgb(254, 254, 254) 45.8%);
            cursor: pointer;

            h4 {
                font-size: 16px;
                color: #fff;
                margin: 10px 0 30px 0;
            }

            del {
                font-size: 12px;
                color: #b0b0b0;
            }

            p {
                font-size: 25px;
                color: red;
                font-weight: bolder;
                margin-bottom: 25px;
            }

            .rgint-add {
                .into {
                    cursor: pointer;
                    width: 80px;
                    border: 1px solid #bf54ff;
                    padding: 8px 10px;
                    border-radius: 5px;
                    color: #bf54ff;
                }
            }

            .ban {
                .into {
                    cursor: not-allowed;
                    width: 80px;
                    border: 1px solid #dddddd6e;
                    background: #dddddd6e;
                    padding: 8px 10px;
                    border-radius: 5px;
                    color: white;
                }
            }
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: #f9f9f9;


    }

    ::-webkit-scrollbar-thumb {
        background-color: #4caf50;
        border-radius: 10px;
        transition: background-color 0.2s ease;


    }

    .factory {
        width: 150px;
        line-height: 18px;
        height: 33px;
        word-wrap: break-word;
        word-break: break-word;
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .box-name {
        width: 150px;
        height: 35px;
        line-height: 18px;
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-weight: bolder;
    }
}

.factory-t {
    margin-top: 10px;
}
</style>