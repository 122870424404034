import { render, staticRenderFns } from "./showPirceType.vue?vue&type=template&id=a05a8628&scoped=true"
import script from "./showPirceType.vue?vue&type=script&lang=js"
export * from "./showPirceType.vue?vue&type=script&lang=js"
import style0 from "./showPirceType.vue?vue&type=style&index=0&id=a05a8628&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a05a8628",
  null
  
)

export default component.exports