<template>
    <div>

        <dl class="item-line">
            <dt class="ns-text-color-gray titl-name">销售价</dt>
            <dd>
                <em class="yuan ns-text-color">¥</em>
                <template v-if="token">
                    <template v-if="goodsSkuDetail.sale_control?.view_price">
                        <span class="price ns-text-color">
                            {{ goodsSkuDetail.discount_price>0?goodsSkuDetail.discount_price : goodsSkuDetail.price }}<span class="price-unit">/{{
                                goodsSkuDetail.unit }}</span>
                        </span>

                    </template>
                    <template v-else>
                        {{ goodsSkuDetail.sale_control?.not_buy_msg }}
                    </template>
                </template>
                <template v-else>
                    <span class="price ns-text-color" @click="$router.push('/login')">
                        登录可见
                    </span>
                </template>
            </dd>
        </dl>
        <!-- <dl class="item-line" v-if="goodsSkuDetail.member_price > 0">
            <dt class="ns-text-color-gray" @click="$router.push('/login')">客户价</dt>
            <dd>
                <em class="market-yuan">¥</em>

                <span class="member_price" v-if="token">{{ goodsSkuDetail.member_price }}</span>
                <span class="member_price" v-else @click="$router.push('/login')">登录可见</span>
            </dd>
        </dl>
        <dl class="item-line" v-if="goodsSkuDetail.market_price > 0">
            <dt class="ns-text-color-gray">市场价</dt>
            <dd>
                <em class="market-yuan">¥</em>

                <span class="market-price" v-if="token">{{ goodsSkuDetail.market_price }}</span>
                <span class="market-price" v-else @click="$router.push('/login')">登录可见</span>
            </dd>
        </dl> -->

        <dl  class="item-line">
        <dt  class="titl-name">原价</dt>
        <dd>
                  <template v-if="token">
                        <template v-if="goodsSkuDetail.sale_control?.view_price">
                           <i style="color: #303133 ; font-size: 14px;"><em>¥</em> {{ goodsSkuDetail.original_price }}</i>
                        </template>
                        <template v-else>
                             {{goodsSkuDetail.sale_control?.not_buy_msg}}
                        </template>
                 </template>
                    <template v-else>
                      <span style="color: #303133 ; font-size: 14px;"> <em>¥</em> {{ storeWithoutAuthority }}</span>
                    </template>
            </dd>
     </dl>
     <dl class="item-line"   v-if="goodsSkuDetail.market_price>0">
            <dt class="titl-name" style="">建议零售价</dt>
            <dd>
                    <template v-if="token">
                        <template v-if="goodsSkuDetail.sale_control?.view_price">
                            <span style="color: #303133 ; font-size: 14px;" ><em>¥</em>{{ goodsSkuDetail.discount_price >0? goodsSkuDetail.discount_price : goodsSkuDetail.market_price  }}</span>
                            <!-- <span style="font-size: 12px;">/{{ goodsSkuDetail.unit }}</span> -->
                        </template>
                        <template v-else>
                            <span style="color: #303133 ; font-size: 14px;"><em>¥</em>{{ goodsSkuDetail.sale_control?.not_buy_msg }}</span>
                        </template>
                    </template>
                    <template v-else>
                        <span style="color: #303133 ; font-size: 14px;"> <em>¥</em>
                            {{ storeWithoutAuthority }}</span>
                    </template>
           
            </dd>
        </dl>
    </div>
</template>

<script>
export default {
    props: {
        goodsSkuDetail: {
            type: Object,
            default: () => { }
        },
        token: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
.titl-name{
    min-width: 88px;
}
</style>
