<template>
    <div>
        <el-tabs class="goods-tab" v-model="tabName" type="card" @tab-click="tabChange">
            <el-tab-pane label="商品详情" name="detail">
                <div v-html="goodsSkuDetail.goods_content"></div>
            </el-tab-pane>
            <!-- <el-tab-pane label="商品属性" name="attr">
                <ul class="attr-list">
                    <template v-if="returnShopClass().length > 0">
                        <li v-for="(item, index) in returnShopClass()" :key="index">{{ item.name }}{{ item.value }}</li>
                    </template>
                </ul>
            </el-tab-pane> -->
            <el-tab-pane v-if="evaluate_show" :label="evaluteCount.total ? '商品评价(' + evaluteCount.total + ')' : '商品评价'"
                name="evaluate" class="evaluate">
                <template v-if="evaluteCount.total">
                    <nav>
                        <li :class="evaluaType == 0 ? 'selected' : ''" @click="evaluationType(0)">全部评价({{
                            evaluteCount.total }})</li>
                        <li :class="evaluaType == 1 ? 'selected' : ''" @click="evaluationType(1)">好评({{
                            evaluteCount.haoping }})</li>
                        <li v-show="false" :class="evaluaType == 2 ? 'selected' : ''" @click="evaluationType(2)">中评({{
                            evaluteCount.zhongping }})</li>
                        <li v-show="false" :class="evaluaType == 3 ? 'selected' : ''" @click="evaluationType(3)">差评({{
                            evaluteCount.chaping }})</li>
                    </nav>
                    <ul class="list">
                        <li v-for="(item, index) in goodsEvaluateList" :key="index">
                            <div class="member-info">
                                <img :src="$img(item.member_headimg)" @error="imageErrorEvaluate(index)"
                                    class="avatar" />
                                <span>{{ item.member_name }}</span>
                            </div>
                            <div class="info-wrap">
                                <el-rate v-model="item.star" disabled></el-rate>
                                <p class="content">{{ item.content }}</p>
                                <div class="img-list" v-if="item.images">
                                    <el-image v-for="(img, img_index) in item.images" :key="img_index" :src="$img(img)"
                                        :preview-src-list="item.imagesFormat"></el-image>
                                </div>
                                <div class="sku-info">
                                    <span>{{ item.sku_name }}</span>
                                    <span class="create-time">{{ $util.timeStampTurnTime(item.create_time) }}</span>
                                </div>
                                <div class="evaluation-reply" v-if="item.explain_first != ''">店家回复：{{ item.explain_first
                                    }}</div>
                                <template v-if="item.again_is_audit == 1">
                                    <div class="review-evaluation">
                                        <span>追加评价</span>
                                        <span class="review-time">{{ $util.timeStampTurnTime(item.again_time) }}</span>
                                    </div>
                                    <p class="content">{{ item.again_content }}</p>
                                    <div class="img-list">
                                        <el-image v-for="(again_img, again_index) in item.again_images"
                                            :key="again_index" :src="$img(again_img)"
                                            :preview-src-list="item.againImagesFormat"></el-image>
                                    </div>
                                    <div class="evaluation-reply" v-if="item.again_explain != ''">店家回复：{{
                                        item.again_explain }}</div>
                                </template>
                            </div>
                        </li>
                    </ul>
                    <div class="pager">
                        <el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
                            :current-page.sync="currentPage" :page-size.sync="pageSize"
                            @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
                            hide-on-single-page></el-pagination>
                    </div>
                </template>
                <div class="empty" v-else>该商品暂无评价哦</div>
            </el-tab-pane>
            <template v-if="service">
                <el-tab-pane v-if="service_is_display.is_display == 1" :label="service.title" name="after_sale"
                    class="after-sale">
                    <div v-html="service.content"></div>
                </el-tab-pane>
            </template>
        </el-tabs>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: ""
        },
        goodsSkuDetail: {
            type: Object,
            default: () => { }
        },
        evaluate_show: {
            type: Boolean,
            default: false
        },
        evaluteCount: {
            type: Object,
            default: () => { }
        },
        goodsEvaluateList: {
            type: Array,
            default: () => []
        },
        service: {
            type: Object,
            default: () => { }
        },
        service_is_display: {
            type: Object,
            default: () => { }
        },
        evaluaType: {
            type: Number,
            default: 1
        },
        currentPage: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 10
        },
        total: {
            type: Number,
            default: 0
        }

    },
    data() {
        return {
        }
    },
    created(){
        
    },
    computed: {
        tabName: {
            get: function () {
                return this.value
            },
            set: function (value) {
                this.$emit('input', value)
            }
        },
    },
    methods: {
        tabChange(tab, event) {
        },
        evaluationType(){
            
        },
        handlePageSizeChange(){

        },
        handleCurrentPageChange(){},
        returnShopClass(){
      
      if(!this.goodsSkuDetail){
         return [];
      }
      let detail = this.goodsSkuDetail;
      let is_medicinal = detail.is_medicinal;
    
      let arr = [
        { name : (is_medicinal===0?'库存：':'产地：') ,value:(is_medicinal===0?detail.stock_name:detail.attr.attr_place)},
        { name : '单位：' ,value:detail.unit},
        { name : '商品批号：' ,value:detail.attr.attr_batch_number},
        { name : '生产日期：' ,value:detail.attr.attr_production_date},
        { name : '效期：',value:detail.attr.attr_validity},
        { name : '保质期：',value:detail.attr.attr_shelf_life},
        { name : '生产厂家：' ,value:detail.attr.attr_factory},
        { name : '规格：' ,value:detail.attr.attr_specs},
        { name : '商品规格：' ,value:detail.attr.attr_goods_attr},
        { name : 'ERPID：',value:detail.goods_erp_spid}
        // { name : '批准文号：' ,value:detail.attr.attr_approval_number},
        // { name : '剂型：' ,value:detail.attr.attr_dosage_form},
        // { name : '中包装：' ,value:detail.min_buy},
        // { name : '国家码：' ,value:detail.attr.attr_country_code},
        // { name : '件装量：' ,value:detail.packsize},
        // { name : '是否GSP管控：' ,value:detail.gspflag ? "是" : "否"},
        // { name : '器械子类：' ,value:detail.qixie_zilei},
      ];
      arr = arr.filter(item => item.value!==''&& item.value!==null&& item.value!==undefined);

      return arr;
    }

    }
}
</script>

<style lang="postcss" scoped></style>